import { useMutation, useQuery } from "react-query";
import * as queryKey from "../queryKeys";
import {
  changePassword,
  deleteAdmin,
  getAdmin,
  getAppSettings,
  updateAdminProfile,
  updateAdminRole,
  updateServiceCharge,
  uploadAdminAvatar,
  getStudentCount,
  updateWithdrawEmail,
  updateSchoolCharge,
  sendPayOtp,
  payProvider,
  sendMassMessage,
  sendSchMessage,
  updatePayStackCharge,
  sendParMessage,
  updateWithdrawalLimit,
  setIncentive,
  updateOneOffServiceCharge,
  updateOverdueLimit,
  getOverdueLimit,
} from "../api/admin";

export const useUpdateAdminProfile = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(updateAdminProfile, {
    mutationKey: queryKey.UPDATE_ADMIN_PROFILE,
    ...options,
  });
  return { mutate, isLoading, data };
};

export const useUpdateWithdrawEmail = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(updateWithdrawEmail, {
    mutationKey: queryKey.UPDATE_WITHDRAW_EMAIL,
    ...options,
  });
  return { mutate, isLoading, data };
};

export const useUploadAdminProfile = (options = {}) => {
  const { mutate, isLoading } = useMutation(uploadAdminAvatar, {
    mutationKey: queryKey.UPLOAD_ADMIN_AVATAR,
    ...options,
  });
  return { mutate, isLoading };
};

export const useChangePassword = (options = {}) => {
  const { mutate, isLoading } = useMutation(changePassword, {
    mutationKey: queryKey.CHANGE_PASSWORD,
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateServiceCharge = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateServiceCharge, {
    mutationKey: queryKey.UPDATE_SERVICE_CHARGE,
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateOneOffServiceCharge = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateOneOffServiceCharge, {
    mutationKey: "updateOneOffServiceCharge",
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateWithdrawalLimit = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateWithdrawalLimit, {
    mutationKey: queryKey.UPDATE_WITHDRAWAL_LIMIT,
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateOverdueLimit = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateOverdueLimit, {
    mutationKey: "updateOverdueLimit",
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdatePayStackCharge = (options = {}) => {
  const { mutate, isLoading } = useMutation(updatePayStackCharge, {
    mutationKey: queryKey.UPDATE_PAYSTACK_CHARGE,
    ...options,
  });
  return { mutate, isLoading };
};

export const useSetIncentive = (options = {}) => {
  const { mutate, isLoading } = useMutation(setIncentive, {
    mutationKey: "setIncentive",
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateSchoolCharge = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateSchoolCharge, {
    mutationKey: queryKey.UPDATE_SCHOOL_CHARGE,
    ...options,
  });
  return { mutate, isLoading };
};

export const useSendPayOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendPayOtp, {
    mutationKey: queryKey.SEND_PAY_OTP,
    ...options,
  });
  return { mutate, isLoading };
};

export const usePayProvider = (options = {}) => {
  const { mutate, isLoading } = useMutation(payProvider, {
    mutationKey: queryKey.PAY_PROVIDER,
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateAdminRole = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateAdminRole, {
    mutationKey: queryKey.UPDATE_ADMIN_ROLE,
    ...options,
  });
  return { mutate, isLoading };
};

export const useDeleteAdmin = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteAdmin, {
    mutationKey: queryKey.DEL_ADMIN,
    ...options,
  });
  return { mutate, isLoading };
};

export const useSendMassMessage = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendMassMessage, {
    mutationKey: "SEND_MASS_MESSAGE",
    ...options,
  });
  return { mutate, isLoading };
};

export const useSendSchMessage = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendSchMessage, {
    mutationKey: "SEND_SCH_MESSAGE",
    ...options,
  });
  return { mutate, isLoading };
};

export const useSendParMessage = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendParMessage, {
    mutationKey: "SEND_PAR_MESSAGE",
    ...options,
  });
  return { mutate, isLoading };
};

export const useGetAdmin = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(queryKey.GET_ADMIN, getAdmin, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetOverdueLimit = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    "getOverdueLimit",
    getOverdueLimit,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetStudentCount = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.STUDENT_COUNT,
    getStudentCount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetAppSettings = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_APP_SETTINGS,
    getAppSettings,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};
