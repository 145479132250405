import {
  agents,
  agentsec,
  cust,
  custsec,
  debit,
  edit,
  fund,
  note,
  noti,
  notisec,
  over,
  oversec,
  rep,
  repsec,
  security,
  securitysec,
  slide1,
  slide2,
  slide3,
  slide4,
  tran,
  transec,
  trash,
  rotate,
  profileTick,
  profileTickSvg,
  messageText,
  bookmark,
  userOctagon,
  cacImg,
} from '../../assets/exports';
import { IoMail } from 'react-icons/io5';
import { BsTelephone } from 'react-icons/bs';
import { TbMail } from 'react-icons/tb';

export const data = {
  general: [
    {
      id: 1,
      icon: over,
      sec: oversec,
      name: 'Overview',
      path: '/overview',
    },
  ],
  acc: [
    {
      id: 1,
      icon: rep,
      sec: repsec,
      name: 'School',
      path: '/schools',
    },
    {
      id: 2,
      icon: cust,
      sec: custsec,
      name: 'Payment Log(Lenco)',
      path: '/payment-log',
    },
    {
      id: 3,
      icon: cust,
      sec: custsec,
      name: 'Parent',
      path: '/parents',
    },
    {
      id: 4,
      icon: tran,
      sec: transec,
      name: 'Transactions',
      path: '/transactions',
    },
    {
      id: 5,
      icon: tran,
      sec: transec,
      name: 'Loan Facility',
      path: '/loan-facility',
    },
  ],
  kyc: [
    {
      id: 1,
      icon: noti,
      sec: notisec,
      name: 'Notifications',
      path: '/notifications',
    },
    {
      id: 2,
      icon: noti,
      sec: notisec,
      name: 'Communication',
      path: '/communication',
    },
  ],
  teams: [
    {
      id: 1,
      icon: agents,
      sec: agentsec,
      name: 'Agents',
      path: '/agents',
    },
    {
      id: 2,
      icon: security,
      sec: securitysec,
      name: 'Roles & Permission',
      path: '/roles&permission',
    },
  ],
};

export const activeStyle = {
  backgroundColor: '#F3C948',
  color: '#fff',
  textAlign: 'center',
  fontSize: '15px',
  fontWeight: '500',
  lineHeight: '10px',
  padding: '10px',
  borderRadius: '5%',
};

export const analytics = [
  {
    id: 1,
    title: 'Total Transaction Volume',
  },
  {
    id: 2,
    title: 'Total Number of Schools',
  },
  {
    id: 3,
    title: 'Total Full Year Plan',
  },
  {
    id: 4,
    title: 'Total One off Plan',
  },
];

export const commType = [
  {
    label: 'Send email',
    value: 'email',
  },
  {
    label: 'Send SMS',
    value: 'sms',
  },
];

export const filters = [
  {
    id: 1,
    name: 'Yesterday',
    value: '1',
  },
  {
    id: 2,
    name: 'Last Week',
    value: '7',
  },
  {
    id: 3,
    name: 'Last 14 days',
    value: '14',
  },
  {
    id: 4,
    name: 'Last 30 days',
    value: '30',
  },
];

export const overTab = ['General Overview', 'Other Statistics'];
export const agentTab = ['All Agents', 'Invitations'];
export const transTab = [
  'Transaction Overview',
  'Transaction History',
  'Withdrawal Requests',
];
export const parentTab = ['All Parents', 'Overdue Parents'];
export const loanTab = [
  'Loan Overview',
  'Other Statistics',
  'Loan Management',
  'Loan Settings',
];
export const statTab = ['Loan History', 'Eligible Schools'];

export const fundActions = [
  {
    title: 'Fund Wallet',
    icon: fund,
  },
  {
    title: 'Debit Wallet',
    icon: debit,
  },
];

export const WalletIcon = ({ fill }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8346 4.70063C11.6746 4.67396 11.5079 4.6673 11.3346 4.6673H4.66793C4.48126 4.6673 4.30126 4.68064 4.12793 4.7073C4.22126 4.52064 4.3546 4.34732 4.5146 4.18732L6.68126 2.01398C7.5946 1.10732 9.0746 1.10732 9.98793 2.01398L11.1546 3.19396C11.5813 3.61396 11.8079 4.1473 11.8346 4.70063Z"
        stroke={fill}
      />
      <path
        d="M6.00033 12.6667C6.00033 13.1667 5.86033 13.64 5.61366 14.04C5.15366 14.8133 4.30699 15.3333 3.33366 15.3333C2.36033 15.3333 1.51366 14.8133 1.05366 14.04C0.806992 13.64 0.666992 13.1667 0.666992 12.6667C0.666992 11.1933 1.86033 10 3.33366 10C4.80699 10 6.00033 11.1933 6.00033 12.6667Z"
        stroke={fill}
      />
      <path d="M4.32846 12.6523H2.3418" stroke={fill} />
      <path d="M3.33398 11.6797V13.673" stroke={fill} />
      <path
        d="M14.6663 7.99935V11.3327C14.6663 13.3327 13.333 14.666 11.333 14.666H5.08634C5.29301 14.4927 5.47301 14.2793 5.61301 14.0393C5.85967 13.6393 5.99967 13.166 5.99967 12.666C5.99967 11.1927 4.80634 9.99935 3.33301 9.99935C2.53301 9.99935 1.81967 10.3527 1.33301 10.906V7.99935C1.33301 6.18602 2.42634 4.91935 4.12634 4.70601C4.29967 4.67935 4.47967 4.66602 4.66634 4.66602H11.333C11.5063 4.66602 11.673 4.67267 11.833 4.69934C13.553 4.89934 14.6663 6.17268 14.6663 7.99935Z"
        stroke={fill}
      />
      <path
        d="M14.6663 8.33398H12.6663C11.933 8.33398 11.333 8.93398 11.333 9.66732C11.333 10.4007 11.933 11.0007 12.6663 11.0007H14.6663"
        stroke={fill}
      />
    </svg>
  );
};

export const walletActions = [
  {
    title: 'Fund Wallet',
    icon: <WalletIcon fill="#2463EB" />,
  },
  {
    title: 'Refund Wallet',
    icon: <WalletIcon fill="#EB5757" />,
  },
];

export const topSplits = [
  {
    id: 1,
    title: 'Total Enrolled Parents',
  },
  {
    id: 2,
    title: 'Total Enrolled Wards',
  },
  {
    id: 3,
    title: 'Total Students',
  },
];
export const topSplit = [
  {
    id: 1,
    title: 'Total Enrolled Parents',
  },
  {
    id: 2,
    title: 'Total Enrolled Wards',
  },
  {
    id: 3,
    title: 'Total Student Count',
  },
];

export const split = [
  {
    id: 1,
    title: 'Total Daily Plan',
    value: '2,570',
  },
  {
    id: 2,
    title: 'Total Weekly Plan',
    value: '1,070',
  },
  {
    id: 3,
    title: 'Total Monthly Plan',
    value: '770',
  },
];
export const report = [
  {
    id: 1,
    title: 'System Balance',
  },
  {
    id: 2,
    title: 'Transaction Volume',
  },
  {
    id: 3,
    title: 'Total Charges',
  },
  {
    id: 4,
    title: 'Total VAT',
  },
];

export const trans = [
  {
    id: 1,
    title: 'System Balance',
  },
  {
    id: 2,
    title: 'Total Withdrawal',
  },
  {
    id: 3,
    title: 'Total Deposit',
  },
  {
    id: 4,
    title: 'Transaction Volume',
  },
  {
    id: 5,
    title: 'Total Charges',
  },
  {
    id: 6,
    title: 'Total V.A.T',
  },
  {
    id: 7,
    title: 'Total Users',
  },
  {
    id: 8,
    title: 'Total Transacting Users',
  },
];

export const subAcc = [
  'Total Sub Account Balance',
  'Total Employee Balance',
  'Total Business Accounts',
  'Total Ajo Accounts',
  'Total Project Accounts',
  'Total Estate Mgt Accounts',
  'Total NGO Accounts',
  "Total Couples' Accounts",
];

export const tranHistory = [
  {
    id: 1,
    title: 'Transaction Volume',
  },
  {
    id: 2,
    title: 'Total Fee Expense',
  },
  {
    id: 3,
    title: 'Total Fee Income',
  },
  {
    id: 4,
    title: 'Inactive Users',
  },
];

export const complianceGrid = [
  {
    id: 1,
    title: 'Total Customers',
  },
  {
    id: 2,
    title: 'New Account',
  },
  {
    id: 3,
    title: 'Approved Customers',
  },
  {
    id: 4,
    title: 'Declined Customers',
  },
];

export const recoGrid = [
  {
    id: 1,
    title: 'Total Flagged Account',
  },
  {
    id: 2,
    title: 'Total Reported Transactions',
  },
  {
    id: 3,
    title: 'Total Suspended Account',
  },
  {
    id: 4,
    title: 'Total Deactivated Account',
  },
];

export const customers = [
  {
    id: 1,
    title: 'Expected School Fees',
  },
  {
    id: 2,
    title: 'Total Account Balance',
  },
  {
    id: 3,
    title: 'Total School Inflow',
  },
  {
    id: 4,
    title: 'Total School Outflow',
  },
  {
    id: 5,
    title: 'Total Overdue',
  },
];

export const schoolGrid = [
  {
    id: 1,
    title: 'Total Transaction Volume',
  },
  {
    id: 2,
    title: 'Total School Inflow',
  },
  {
    id: 3,
    title: 'Total School Outflow',
  },
  {
    id: 4,
    title: 'Total Overdue',
  },
];

export const parentGrid = [
  {
    id: 1,
    title: 'Total Enrolled Parent',
    value: '3,080',
  },
  {
    id: 2,
    title: 'Total Parents on Daily Plan',
    value: '80',
  },
  {
    id: 3,
    title: 'Total Parents on Weekly Plan',
    value: '970',
  },
  {
    id: 4,
    title: 'Total Parents on Monthly Plan',
    value: '970',
  },
  {
    id: 5,
    title: 'Total Parents on One off Plan',
    value: '970',
  },
  {
    id: 6,
    title: 'Total Parents on Full Year Plan',
    value: '970',
  },
];

export const provGrid = [
  {
    id: 1,
    title: 'Total Approved Loan',
  },
  {
    id: 2,
    title: 'Total Pending Loan',
  },
  {
    id: 3,
    title: 'Total Declined Loan',
  },

  {
    id: 4,
    title: 'Total Repaid Loan',
  },
];

export const loanGrid = [
  {
    id: 1,
    title: 'Total Loan Request',
  },
  {
    id: 2,
    title: 'Total Amount Disbursed',
  },
  {
    id: 3,
    title: 'Total Amount Repaid',
  },
];

export const loanModalGrid = [
  {
    id: 1,
    title: 'Loan Amount',
  },
  {
    id: 2,
    title: 'Amount Repaid',
  },
  {
    id: 3,
    title: 'Outstanding',
  },
  {
    id: 4,
    title: 'Overdue',
  },
];

export const loanStatHeader = [
  'Loan Date',
  'Amount Requested',
  'Amount Approved',
  'School',
  'Provider',
  'Interest Rate',
  'Status',
  '',
];

export const loanOverviewHeader = [
  'Loan Date',
  'Amount',
  'School',
  'Provider',
  'Interest Rate',
  'Status',
  '',
];

export const transHistory = [
  {
    id: 1,
    title: 'Total Transaction Volume',
  },
  {
    id: 2,
    title: 'Total Cash Inflow',
  },
  {
    id: 3,
    title: 'Total Cash Outflow',
  },
  {
    id: 4,
    title: 'Total Overdue Payment',
  },
];

export const transGrid = [
  {
    id: 1,
    title: 'Total Transaction Volume',
  },
  {
    id: 2,
    title: 'Total Cash Inflow',
  },
  {
    id: 3,
    title: 'Total Cash Outflow',
  },
  {
    id: 4,
    title: 'Total Daily Inflow',
  },
  {
    id: 5,
    title: 'Total Weekly Inflow',
  },
  {
    id: 6,
    title: 'Total Monthly Inflow',
  },
  {
    id: 7,
    title: 'Total One off Inflow',
  },
  {
    id: 8,
    title: 'Total Full Year Inflow',
  },
];

export const revFilter = [
  {
    id: 1,
    day: 'Daily',
  },
  {
    id: 2,
    day: 'Weekly',
  },
  {
    id: 3,
    day: 'Monthly',
  },
];

export const perf = [
  {
    id: 1,
    type: 'Couples',
  },
  {
    id: 1,
    type: 'Business',
  },
  {
    id: 1,
    type: 'Ajo',
  },
];

export const recHeaders = [
  'Date',
  'User',
  'Transaction ID',
  'Amount',
  'Fee',
  'Status',
  '',
];

export const custHeaders = [
  'School ID',
  'School Name',
  'Wallet Balance',
  'Total Parents',
  'Account Number',
  '',
];

export const parTran = ['Transaction', 'Transaction Ref', 'Amount', 'Status'];

export const studentHeader = ['Full Name', 'Class', 'School Fees'];
export const classesHeader = ['Class', 'School Fees', ''];

export const parHeader = [
  'Full Name',
  'Email Address',
  'Phone Number',
  'Active Plan',
];

export const parHeaders = [
  'Full Name',
  'Email Address',
  'Phone Number',
  'School Name',
  'Number of Wards',
  'Active Plan',
  '',
];

export const agentHeaders = [
  'Full Name',
  'Email Address',
  'Roles',
  'Status',
  '',
];

export const roleHeaders = ['Role Name', 'Created By', 'Team Members', ''];

export const slideshow = [slide1, slide2, slide3, slide4];

export const invAgentHeaders = [
  'Full Name',
  'Email Address',
  'Roles',
  'Invited By',
  'Status',
];

export const tranHeaders = ['Transaction', 'School', 'Amount', 'Status', ''];
export const tranParentHeaders = [
  'Transaction',
  'Parent',
  'School',
  'Amount',
  'Status',
  '',
];

export const recTab = ['Deposits', 'Withdrawals'];

export const salesFilt = ['7 days', '30 days', '3 month', '6 month', '1 year'];

export const accTypes = [
  {
    id: 1,
    title: 'Ajo',
    color: '#FF535C',
    val: '5%',
  },
  {
    id: 2,
    title: 'Couples',
    color: '#F3C948',
    val: '5%',
  },
  {
    id: 3,
    title: 'Projects',
    color: '#7B47CC',
    val: '5%',
  },
  {
    id: 4,
    title: 'Business',
    color: '#2F80ED',
    val: '20%',
  },
  {
    id: 5,
    title: 'NGO',
    color: '#BB6BD9',
    val: '20%',
  },
  {
    id: 6,
    title: 'Estate MGT',
    color: '#27AE60',
    val: '30%',
  },
];

export const periodFilter = ['Daily', 'Weekly', 'Monthly'];

export const activities = [
  {
    id: 1,
    title: 'View Transaction Details',
    icon: note,
  },
  {
    id: 2,
    title: 'Remove from recent',
    icon: trash,
  },
];

export const permissionLabels = {
  addParentAndWards: 'Add Parent and Wards',
  approveAndDeclineLoan: 'Approve / Decline Loan',
  deleteParentAndWards: 'Delete Parent and Wards',
  editAppSetting: 'Edit App Settings',
  editRolesAndPermissions: 'Edit Roles and Permission',
  fundWallet: 'Fund Wallet',
  removeOverduePayment: 'Remove Overdue Payment',
  updateParentAndWards: 'Update Parent and Wards',
  updateSchool: 'Update School',
  updateTransactions: 'Update Transactions',
  viewAppSetting: 'View App Settings',
  viewLoanFacilityPage: 'View Loan Facility',
  viewNotifications: 'View Notifications',
  viewOverview: 'View Overview',
  viewParentAndWards: 'View Parent and Wards',
  viewSchool: 'View School',
  viewSuggestedSchools: 'View Suggested Schools',
  viewTransactionPage: 'View Transaction Page',
};

export const cusAct = [
  {
    id: 1,
    title: 'View Details',
    icon: note,
  },
];

export const updateAct = [
  {
    id: 1,
    title: 'Update',
    icon: note,
  },
];

export const adminAct = [
  {
    id: 1,
    title: 'View Roles',
    icon: note,
  },
  {
    id: 2,
    title: 'Update Roles',
    icon: note,
  },
  {
    id: 3,
    title: 'Delete Admin',
    icon: trash,
  },
];

export const roleAct = [
  {
    id: 1,
    title: 'View Details',
    icon: note,
  },
  {
    id: 2,
    title: 'Edit Details',
    icon: edit,
  },
];

export const extraAct = [
  {
    id: 1,
    title: 'View Details',
    icon: note,
  },
  {
    id: 2,
    title: 'Delete Extra',
    icon: trash,
  },
];

export const parentPlan = [
  {
    id: 1,
    name: 'All Plans',
    value: '',
  },
  {
    id: 2,
    name: 'Daily Plan',
    value: 'Daily',
  },
  {
    id: 3,
    name: 'Weekly Plan',
    value: 'Weekly',
  },
  {
    id: 4,
    name: 'Monthly Plan',
    value: 'Monthly',
  },
  {
    id: 5,
    name: 'One off Plan',
    value: 'One-off',
  },
  {
    id: 6,
    name: 'Full Year Plan',
    value: 'FullYear',
  },
];

export const parentFilter = [
  {
    id: 1,
    name: 'All Parents',
    value: '',
  },
  {
    id: 2,
    name: 'Active Parents',
    value: 'active',
  },
  {
    id: 3,
    name: 'Inactive Parents',
    value: 'pending',
  },
];

export const loanPlan = [
  {
    id: 1,
    name: 'All Sub-plan',
    value: '',
  },
  {
    id: 2,
    name: 'Monthly Plan',
    value: '',
  },
  {
    id: 3,
    name: 'Weekly Plan',
    value: '',
  },
  {
    id: 4,
    name: 'Daily Plan',
    value: '',
  },
];

export const tranFilter = [
  {
    id: 1,
    name: 'All Transactions',
    value: '',
  },
  {
    id: 2,
    name: 'Credit Transactions',
    value: 'Credit',
  },
  {
    id: 3,
    name: 'Debit Transactions',
    value: 'Debit',
  },
  {
    id: 3,
    name: 'Enrollment Bonus',
    value: 'Enrollment Bonus',
  },
];

export const statFilter = [
  {
    id: 1,
    name: 'All Status',
    value: '',
  },
  {
    id: 2,
    name: 'Successful',
    value: 'successful',
  },
  {
    id: 3,
    name: 'Pending',
    value: 'pending',
  },
  {
    id: 4,
    name: 'Failed',
    value: 'failed',
  },
];

export const payFilter = [
  {
    id: 1,
    name: 'All Payment',
    value: '',
  },
  {
    id: 2,
    name: 'Card',
    value: 'card',
  },
  {
    id: 3,
    name: 'Wallet',
    value: 'wallet',
  },
  {
    id: 3,
    name: 'Transfer',
    value: 'transfer',
  },
];

export const extraType = [
  {
    id: 1,
    name: 'Recurrent',
    value: 'recurrent',
  },
  {
    id: 2,
    name: 'One-off',
    value: 'one-off',
  },
];

export const classAct = [
  {
    id: 1,
    title: 'View Record',
    icon: note,
  },
  {
    id: 1,
    title: 'View Extras',
    icon: note,
  },
  {
    id: 2,
    title: 'Delete Class record',
    icon: trash,
  },
];

export const loanAction = [
  {
    id: 1,
    title: 'Approve Loan Request',
    icon: '',
  },
  {
    id: 2,
    title: 'Pend Loan Request',
    icon: '',
  },
  {
    id: 3,
    title: 'Decline Loan Request',
    icon: '',
  },
];

export const loanHist = ['Cancel Loan', 'Pause Loan'];
export const loanActions = ['Approve Loan Request', 'Decline Loan Request'];
export const schoolDoc = [
  'CAC Certificate',
  'Memart Doc',
  'Bank Statement',
  'Government Approval',
  'Board Resolution',
];

export const share = ['Utility Bill', 'Guarantee', ''];

export const loanProvGrid = [
  {
    id: 1,
    title: 'Total Approved Loan',
  },
  {
    id: 2,
    title: 'Total Pending Loan',
  },
  {
    id: 3,
    title: 'Total Declined Loan',
  },
  {
    id: 4,
    title: 'Total Repaid Loan',
  },
  {
    id: 5,
    title: 'Total Overdue Payment',
  },
];

export const provAct = [
  {
    id: 1,
    title: 'View Details',
    icon: note,
  },
  {
    id: 2,
    title: 'Edit Details',
    icon: note,
  },
  {
    id: 3,
    title: "Delete Provider's record",
    icon: trash,
  },
];

export const loanAct = [
  {
    id: 1,
    title: 'View Details',
    icon: note,
  },
  {
    id: 2,
    title: "Delete Customer's record",
    icon: trash,
  },
];

export const useraction = [
  {
    id: 1,
    title: 'Suspend Account',
  },
  {
    id: 2,
    title: 'Flag Account - FRAUD',
  },
  {
    id: 3,
    title: 'Deactivate Account',
  },
  {
    id: 4,
    title: 'View Transaction History',
    opt: 'Print Statement',
  },
];

export const extraAction = [
  {
    id: 1,
    title: 'Edit Details',
    icon: note,
  },
  {
    id: 3,
    title: 'Delete record',
    icon: trash,
  },
];

export const defExtraHeader = ['Title', 'Fees', 'Type', ''];

export const parAct = [
  {
    id: 1,
    title: 'View Details',
    icon: note,
  },
  {
    id: 2,
    title: 'Activate / Deactivate Parent',
    icon: note,
  },
  {
    id: 3,
    title: 'Delete Parent record',
    icon: trash,
  },
];

export const subAct = [
  {
    id: 1,
    title: 'View Employee Details',
    icon: note,
  },
  {
    id: 2,
    title: "Delete Customer's record",
    icon: trash,
  },
];

export const susAct = ['Check Account Details', 'Re-activate Account'];
export const repAct = [
  'Check Transaction Status',
  'Reverse Funds',
  'Complete Request',
];

export const usericons = [IoMail, BsTelephone, rotate, profileTick];
export const usericon = [
  {
    id: 1,
    icon: TbMail,
    link: '',
  },
];

export const childRecord = [
  {
    id: 0,
    title: 'School Fees',
    fees: '₦ 35,570',
  },
  {
    id: 1,
    title: 'Amount Paid',
    fees: '₦ 10,000',
  },
  {
    id: 2,
    title: 'Amount Left',
    fees: '₦ 15,000',
  },
  {
    id: 3,
    title: 'Paid Week',
    fees: '4 weeks',
  },
  {
    id: 4,
    title: 'Weeks Left',
    fees: '4 weeks',
  },
];

export const loanflow = [
  {
    id: 1,
    title: 'Loan Amount',
  },
  {
    id: 2,
    title: 'Amount Repaid',
  },
  {
    id: 3,
    title: 'Outstanding',
  },
];

export const parentDetailsHeader = ['Name', 'Class', 'School Fee', ''];
export const extraDetailsHeader = ['Name', 'Extra', 'Fee', 'Type'];
export const parentTransHeader = [
  'Date',
  'Amount',
  'Service Charge',
  'Status',
  '',
];

export const parOverdueHeader = ['Enrollment', 'Amount', ''];
export const parentOverTransHeader = [
  'Date',
  'Amount',
  'Service Charge',
  'Type',
  'Status',
];

export const studTabs = [
  'Ward Details',
  'Transactions',
  'Other Transactions',
  'Extras',
  'Overdue',
];
export const userflow = [
  {
    id: 1,
    title: 'Total Amount',
  },
  {
    id: 2,
    title: 'Total Outstanding',
  },
  {
    id: 3,
    title: 'Amount Paid',
  },
  {
    id: 4,
    title: 'Amount Overdue',
  },
];

export const wardAction = [
  {
    id: 1,
    title: 'Update Ward',
    icon: note,
  },
  {
    id: 2,
    title: 'Delete Ward',
    icon: trash,
  },
];

export const userflows = [
  {
    id: 1,
    title: 'Wallet Balance',
  },
  {
    id: 2,
    title: 'Total Amount',
  },
  {
    id: 3,
    title: 'Amount Paid',
  },
  {
    id: 4,
    title: 'Amount Left',
  },
];

export const planUpdate = [
  {
    title: 'Change Plan',
    value: 'planName',
    desc: 'You can switch to a preferred plan',
  },
  {
    title: 'Update Plan Record',
    value: 'planRecord',
    desc: 'Plan records can be updated with ease.',
  },
];

export const useractions = [
  {
    id: 1,
    title: 'Fund Wallet',
  },
  {
    id: 2,
    title: 'View School Record History',
  },
  {
    id: 3,
    title: 'Suspend Account',
  },
  {
    id: 4,
    title: 'Deactivate Account',
  },
  {
    id: 5,
    title: 'Communication',
  },
  {
    id: 6,
    title: 'Freeze Account',
  },
];

export const userTabs = ['All Wards', 'Recent Transactions'];
export const subTabs = ['Employee Details', 'Signatory Details'];

export const kycTab = ['Approved Doc', 'Pending Doc'];

export const subTab = ['Business Acct', 'Project Acct'];
export const busTab = ['Employee Details', 'Signatory '];

export const providerDetails = [
  'Loan Date',
  'Amount Requested',
  'Amount Approved',
  'School',
  'Repayment Amount',
  'Status',
  '',
];

export const approvedDetails = [
  'Loan Date',
  'School',
  'Repayment Amount',
  'Status',
  '',
];

export const providerHead = [
  'Loan Provider',
  'Interest Rate',
  'Repayment Plan',
  '',
];

export const eligibleHeader = ['School', 'Expected Revenue', 'Eligible Amount'];

export const busGrid = [
  {
    id: 1,
    title: 'Total Balance',
    val: '₦ 35,400',
  },
  {
    id: 2,
    title: 'Total Employee Balance',
    val: '₦ 35,400',
  },
  {
    id: 3,
    title: 'Total Signatories',
    val: '4',
  },
  {
    id: 4,
    title: 'Total Employees',
    val: '14',
  },
];

export const subGrid = [
  {
    id: 1,
    title: 'Total Employee Balance',
    val: '₦ 35,400',
  },
  {
    id: 2,
    title: 'Total Employees',
    val: '35',
  },
];

export const tranChart = [
  {
    id: 1,
    color: '#7B47CC',
    title: 'ATM Withdrawal',
    val: '30%',
  },
  {
    id: 2,
    color: '#F3C948',
    title: 'Bank Transfer',
    val: '70%',
  },
];

export const tranTab = ['Transaction Overview', 'Transaction History'];

export const compTab = ['KYC Verification', 'Reconciliation'];

export const historyHead = [
  'Transaction',
  'Amount',
  'Fee',
  'Balance before',
  'Balance after',
  'Status',
  '',
];

export const tranHeader = [
  'Date',
  'Amount',
  'Fee',
  'Balance before',
  'Balance after',
  'Status',
  '',
];

export const classes = [
  'Creche',
  'KG 1',
  'KG 2',
  'Nursery 1',
  'Nursery 2',
  'Play Group 1',
  'Play Group 2',
  'Basic 1',
  'Basic 2',
  'Basic 3',
  'Basic 4',
  'Basic 5',
  'Basic 6',
  'Primary 1',
  'Primary 2',
  'Primary 3',
  'Primary 4',
  'Primary 5',
  'Primary 6',
  'Grade 1',
  'Grade 2',
  'Grade 3',
  'Grade 4',
  'Grade 5',
  'Grade 6',
  'LDC1',
  'LDC2',
  'LDC3',
  'LDC4',
  'JSS 1',
  'JSS 2',
  'JSS 3',
  'SSS 1',
  'SSS 2',
  'SSS 3',
  'Preparatory School',
  'Pre-nursery ',
  'Pre-primary',
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
];

export const recordHeader = [
  'Class',
  'Num of Days in a Term',
  'Num of Weeks in a Term',
  'Num of Months in a Term',
  'School Fees',
  '',
];

export const extrasHead = ['Title', 'Number of Students', 'Fee', 'Type', ''];

export const compHead = [
  'Name',
  'Account Type',
  'Sub Account',
  'Phone Number',
  'KYC Level',
  'Status',
  '',
];

export const subHead = [
  'Name',
  'Sub Account Type',
  'Total Signatory',
  'No. of Employee',
  'Acc Balance',
  'Status',
  '',
];

export const susHead = [
  'Transaction',
  'Email Address',
  'Balance before',
  'Balance after',
  'Reason for Suspension',
  'Status',
  '',
];

export const repHead = [
  'Customer Name',
  'Amount in dispute',
  'Dispute Type',
  'Description',
  'Status',
  '',
];

export const accHead = [
  'Transaction',
  'Amount',
  'Fee',
  'Balance before',
  'Balance after',
  'Status',
];

export const repHeader = [
  'Transaction Type',
  'Total V.A.T',
  'Total Charges',
  'Date',
  'Balance before',
  'Balance after',
];

export const reconFilter = [
  'Reported Transactions',
  'Suspended/Flagged Accounts',
  'Archived Doc',
];

export const profileTab = [
  'Profile settings',
  'Account security',
  'Service Charge',
  'Incentives',
];

export const schAccounts = [
  {
    id: 1,
    name: 'HONEYLAND COLLEGE, BARUWA',
    bal: '35,570,147',
  },
  {
    id: 2,
    name: 'HONEYLAND COLLEGE, BARUWA',
    bal: '35,570,147',
  },
  {
    id: 3,
    name: 'HONEYLAND COLLEGE, BARUWA',
    bal: '35,570,147',
  },
  {
    id: 4,
    name: 'HONEYLAND COLLEGE, BARUWA',
    bal: '35,570,147',
  },
];

export const kycData = [
  {
    id: 1,
    title: 'customer details',
    info1: 'Adetola Adele',
    info2: '  Adetolaadele@gmail.com',
    info3: '08056746575',
    info4: '3rd Floor, Top Notch Plaza Abraham Adesanya, Ajah, Lagos',
    icon: userOctagon,
  },
  {
    id: 2,
    title: 'NIN/BVN',
    info1: ' *****75784',
    info2: '*****65774',
    icon: profileTickSvg,
  },

  {
    id: 3,
    title: 'cac documents',
    info1: '87656633738475784',
    info2: '06/May/2024',
    icon: messageText,
  },
  {
    id: 4,
    title: 'Bussiness details',
    info1: 'Adetola Adele',
    info2: '  Adetolaadele@gmail.com',
    info3: '08056746575',
    info4: '3rd Floor, Top Notch Plaza Abraham Adesanya, Ajah, Lagos',
    icon: bookmark,
  },
];

export const paymentLogHeader = [
  'Payment ID',
  'Event',
  'Identifier',
  'Data',
  'Amount',
  'Description',
  'Created At',
  'Status',
  ''
];

export const paymentLogData = [
  {
    id: 'FM2022-TRANS-IPDE0001',
    event: 'FM2022..',
    identifier: 'FM2022',
    data: 'PDE0001',
    amount: '35,000',
    desc: 'Deposit',
    created: 'Dec 10,2021 10:40pm',
    status: 'Successful',
  },
    {
    id: 'FM2022-TRANS-IPDE0001',
    event: 'FM2022..',
    identifier: 'FM2022',
    data: 'PDE0001',
    amount: '35,000',
    desc: 'Deposit',
    created: 'Dec 10,2021 10:40pm',
    status: 'Successful',
  },
    {
    id: 'FM2022-TRANS-IPDE0001',
    event: 'FM2022..',
    identifier: 'FM2022',
    data: 'PDE0001',
    amount: '35,000',
    desc: 'Deposit',
    created: 'Dec 10,2021 10:40pm',
    status: 'Successful',
  },
    {
    id: 'FM2022-TRANS-IPDE0001',
    event: 'FM2022..',
    identifier: 'FM2022',
    data: 'PDE0001',
    amount: '35,000',
    desc: 'Deposit',
    created: 'Dec 10,2021 10:40pm',
    status: 'Successful',
  },
    {
    id: 'FM2022-TRANS-IPDE0001',
    event: 'FM2022..',
    identifier: 'FM2022',
    data: 'PDE0001',
    amount: '35,000',
    desc: 'Deposit',
    created: 'Dec 10,2021 10:40pm',
    status: 'Successful',
  },
];
