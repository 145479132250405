import React, { useEffect, useState } from "react";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { slideshow } from "../../../components/common/constants";
import { BsCircle, BsFillCircleFill } from "react-icons/bs";
import { verified } from "../../../assets/exports";

const SideBarr = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((slideIndex) => (slideIndex + 1) % slideshow.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const renderCircle = (index) => {
    const isActive = index === slideIndex;
    const color = isActive ? "#F3C948" : "#2463EB";
    const opacity = isActive ? 1 : 0.5;
    return (
      <Box key={index}>
        {isActive ? (
          <BsFillCircleFill size="10px" color={color} opacity={opacity} />
        ) : (
          <BsCircle size="10px" color={color} opacity={opacity} />
        )}
      </Box>
    );
  };

  return (
    <Flex
      minH={"100vh"}
      pos="fixed"
      right="0"
      align="center"
      justifyContent="center"
      w="50%"
      pb="30px"
      bg="#0F172B"
      flexDirection="column"
    >
      <Flex flexDir="column" align="center" justifyContent="center" flex="1">
        <Box w="450px" pos="relative" h="360px" overflow="hidden">
          <Flex
            pos="absolute"
            w={`${slideshow.length}00%`}
            h="100%"
            transition={`transform ${4000 / 1000}s ease-in-out`}
            transform={`translateX(-${slideIndex * (100 / slideshow.length)}%)`}
          >
            {slideshow.map((data, i) => (
              <Box
                key={i}
                w={`${100 / slideshow.length}%`}
                h="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Image h="350px" src={data} />
              </Box>
            ))}
          </Flex>
        </Box>

        <Center flexDir="column" textAlign="center" mt="40px">
          <Flex
            w="fit-content"
            bg="orangeColor"
            px="12px"
            rounded="full"
            gap="4px"
            py="5px"
          >
            <Image src={verified} />
            <Text>Edupali</Text>
          </Flex>
          <Text color="#fff" my="10px" fontWeight={500}>
            Connect with Every Student!
          </Text>

          <Text fontSize="14px" fontWeight={300} color="#e0e0e0">
            Set up automated daily, weekly and monthly payments for added <br />
            convenience and stay on top of your expenses.{" "}
          </Text>
        </Center>
      </Flex>

      <Flex gap="15px" mt="30px">
        {[...Array(slideshow.length)].map((_, index) => (
          <Box key={index}>{renderCircle(index)}</Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default SideBarr;
