export const PUBLIC_PATHS = {
  LOGIN: '/login',
  CHANGE_PASS: '/change-password',
  RESET_PASS: '/reset-password',
  SIGNUP: '/signup',
};

export const PRIVATE_PATHS = {
  OVERVIEW: '/overview',
  SCHOOL: '/schools',
  SCHOOL_DETAILS: '/school-details/:id',
  PARENT: '/parents',
  AGENT: '/agents',
  ROLES: '/roles&permission',
  COMMUNICATION: '/communication',
  TRANSACTION: '/transactions',
  LOAN: '/loan-facility',
  PROVIDER: '/loan-facility/provider/:id',
  SCHOOL_REC: '/school/:name/:id/records',
  EXTRAS: '/school/:name/:id/records/extras/:classId',
  SETTINGS: '/settings',
  KYC: '/school-details/kyc',
  PAYMENT_LOG: '/payment-log',
};
