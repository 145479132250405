export const LOGIN = 'LOGIN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SEND_PASSWORD_OTP = 'SEND_PASSWORD_OTP';
export const CREATE_DIVISION = 'CREATE_DIVISION';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const CHANGE_FIRST_PASSWORD = 'CHANGE_FIRST_PASSWORD';
export const UPDATE_EXTRA = 'UPDATE_EXTRA';
export const CREATE_ROLES = 'CREATE_ROLES';
export const DELETE_DIVISION = 'DELETE_DIVISION';
export const DELETE_SCHOOl = 'DELETE_SCHOOl';
export const SUSPEND_SCHOOl = 'SUSPEND_SCHOOl';
export const DELETE_EXTRA = 'DELETE_EXTRA';
export const EDIT_ROLES = 'EDIT_ROLES';
export const INV_ADMIN = 'INV_ADMIN';
export const DEL__DEF_EXTRA = 'DEL__DEF_EXTRA';
export const GET_DEFAULT_EXTRAS = 'GET_DEFAULT_EXTRAS';
export const UPDATE_DEF_EXTRA = 'UPDATE_DEF_EXTRA';
export const CREATE_DEF_EXTRA = 'CREATE_DEF_EXTRA';
export const CREATE_EXTRA = 'CREATE_EXTRA';
export const INV_PARENT = 'INV_PARENT';
export const UPDATE_PAR_STATUS = 'UPDATE_PAR_STATUS';
export const FUND_PARENT = 'FUND_PARENT';
export const GET_PAID_DURATION = 'GET_PAID_DURATION';
export const DEBIT_PARENT = 'DEBIT_PARENT';
export const DELETE_PARENT = 'DELETE_PARENT';
export const CREATE_STUDENT = 'CREATE_STUDENT';
export const TOTAL_TRANSACTION_VOLUME = 'TOTAL_TRANSACTION_VOLUME';
export const GET_PARENTS = 'GET_PARENTS';
export const GET_OVERDUE_PARENT = 'GET_OVERDUE_PARENT';
export const GET_PAREN = 'GET_PAREN';
export const PAR_TRAN = 'PAR_TRAN';
export const GET_ADMIN = 'GET_ADMIN';
export const STUDENT_COUNT = 'STUDENT_COUNT';
export const GET_APP_SETTINGS = 'GET_APP_SETTINGS';
export const DEL_DISCOUNT = 'DEL_DISCOUNT';
export const UPDATE_PARENT = 'UPDATE_PARENT';
export const FUND_WALLET = 'FUND_WALLET';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_TRANSACTION = 'UPDATE_PLAN_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const GIVE_DICOUNT = 'GIVE_DICOUNT';
export const DELETE_WARD = 'DELETE_WARD';
export const GET_LOAN_PROVIDER = 'GET_LOAN_PROVIDER';
export const ELIGIBLE_SCHOOL = 'ELIGIBLE_SCHOOL';
export const TOTAL_SCHOOL = 'TOTAL_SCHOOL';
export const TOTAL_PARENT = 'TOTAL_PARENT';
export const GET_ADMINS = 'GET_ADMINS';
export const TOTAL_WARDS = 'TOTAL_WARDS';
export const GET_PLANS_COUNT = 'GET_PLANS_COUNT';
export const APPROVED_LOANS = 'APPROVED_LOANS';
export const REQUESTED_LOANS = 'REQUESTED_LOANS';
export const PAID_LOANS = 'PAID_LOANS';
export const LOAN_PROV_HISTORY = 'LOAN_PROV_HISTORY';
export const GET_SCHOOL_PARENT = 'GET_SCHOOL_PARENT';
export const UPDATE_SERVICE_CHARGE = 'UPDATE_SERVICE_CHARGE';
export const UPDATE_WITHDRAWAL_LIMIT = 'UPDATE_WITHDRAWAL_LIMIT';
export const UPDATE_PAYSTACK_CHARGE = 'UPDATE_PAYSTACK_CHARGE';
export const UPDATE_SCHOOL_CHARGE = 'UPDATE_SCHOOL_CHARGE';
export const SEND_PAY_OTP = 'SEND_PAY_OTP';
export const PAY_PROVIDER = 'PAY_PROVIDER';
export const UPDATE_ADMIN_ROLE = 'UPDATE_ADMIN_ROLE';
export const DEL_ADMIN = 'DEL_ADMIN';
export const BANK_CODE = 'BANK_CODE';
export const VALIDATE_BANK = 'VALIDATE_BANK';
export const GET_PLANS_PERFOMANCE = 'GET_PLANS_PERFOMANCE';
export const GET_CASH_OUTFLOW = 'GET_CASH_FLOUTOW';
export const GET_CASH_INFLOW = 'GET_CASH_INFLOW';
export const GET_PLAN_FLOW = 'GET_PLAN_FLOW';
export const GET_LAST_SIX_MONTH = 'GET_LAST_SIX_MONTH';
export const GET_SCHOOLS_TRANSACTION = 'GET_SCHOOLS_TRANSACTION';
export const GET_PARENT_TRANSACTIONS = 'GET_PARENT_TRANSACTIONS';
export const GET_SCHOOL_TRANSACTION = 'GET_SCHOOL_TRANSACTION';
export const GET_TOP_PARENT = 'GET_TOP_PARENT';
export const GET_WITHDRAWAL_REQUEST = 'GET_WITHDRAWAL_REQUEST';
export const APPROVE_WITHDRAWAL = 'APPROVE_WITHDRAWAL';
export const GET_SCHOOLS = 'GET_SCHOOLS';
export const GET_ROLES = 'GET_ROLES';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_STUDENT_COUNT = 'GET_STUDENT_COUNT';
export const GET_SCHOOL_DETAIL = 'GET_SCHOOL_DETAIL';
export const INITIATE_OVERDUE = 'INITIATE_OVERDUE';
export const GET_PARENT_DETAILS = 'GET_PARENT_DETAILS';
export const GET_SCHOOL_FLOW = 'GET_SCHOOL_FLOW';
export const TOTAL_STUDENT = 'TOTAL_STUDENT';
export const GET_EXTRA_COUNT = 'GET_EXTRA_COUNT';
export const UPDATE_ADMIN_PROFILE = 'UPDATE_ADMIN_PROFILE';
export const UPDATE_WITHDRAW_EMAIL = 'UPDATE_WITHDRAW_EMAIL';
export const UPLOAD_ADMIN_AVATAR = 'UPLOAD_ADMIN_AVATAR';
export const CREATE_LOAN_PROVIDER = 'CREATE_LOAN_PROVIDER';
export const LOAN_PROVIDER_LOGO = 'LOAN_PROVIDER_LOGO';
export const LOAN_HISTORY = 'LOAN_HISTORY';
export const APPROVE_LOAN = 'APPROVE_LOAN';
export const UPDATE_MIN_LOAN = 'UPDATE_MIN_LOAN';
export const DECLINE_LOAN = 'DECLINE_LOAN';
export const GET_LOAN_DETAIL = 'GET_LOAN_DETAIL';
export const UPDATE_LOAN_PROVIDER = 'UPDATE_LOAN_PROVIDER';
export const DELETE_LOAN_PROVIDER = 'DELETE_LOAN_PROVIDER';
export const LOAN_RATE = 'LOAN_RATE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_PARENT_PLANS = 'GET_PARENT_PLANS';
export const GET_SINGLE_SCHOOL_PARENTS = 'GET_SINGLE_SCHOOL_PARENT';
export const GET_TOTAL_OVERDUE = 'GET_TOTAL_OVERDUE ';
export const GET_TOTAL_FEE = 'GET_TOTAL_FEE';
export const UPDATE_DIVISION_PLAN = 'UPDATE_DIVISION_PLAN';
export const GET_DIVISION = 'GET_DIVISION';
export const GET_PLAN_OUTSTANDING = 'GET_PLAN_OUTSTANDING ';
export const GET_TOTAL_AMOUNT = 'GET_TOTAL_AMOUNT';
export const GET_TOTAL_PAID = 'GET_TOTAL_PAID';
export const GET_EXPECTED_PAYMENT = 'GET_EXPECTED_PAYMENT';