import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@chakra-ui/react";
import SideDrawer from "./SideDrawer";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

const Header = () => {
  const [showDrawerMenu, setShowDrawerMenu] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 991px)");
  const toggleSidebar = () => {
    setShowDrawerMenu(true);
  };
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  return (
    <Box
      py="20px"
      bg={scroll ? "rgba(255, 255, 255, 0.15)" : "#fff"}
      backdropFilter={"blur(10px)"}
      pos="fixed"
      w="100%"
      zIndex="5"
      mt={isMobile ? -3 : 0}
      pl={!isMobile ? "285px" : "5px"}
    >
      <HiOutlineMenuAlt2 size={32} cursor="pointer" onClick={toggleSidebar} />
      <SideDrawer
        isOpen={showDrawerMenu}
        onClose={() => setShowDrawerMenu(false)}
      />
    </Box>
  );
};

export default Header;
