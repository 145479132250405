import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";
import { useLogOut } from "../../utils/helpers";

const LogoutModal = ({ isOpen, onClose }) => {
  const logOut = useLogOut();

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent overflowY="auto" borderRadius="20px" bg="#fff" color="#000">
        <ModalHeader>Logout</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to log out?</ModalBody>
        <Flex gap="1rem" m="20px">
          <Button onClick={logOut} h="60px" w="full">
            Log Out
          </Button>
          <Button variant="secondary" h="60px" w="full" onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
export default LogoutModal;
