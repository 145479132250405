import React, { useState } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { data } from "../../common/constants";
import { log, logo, pfp } from "../../../assets/exports";
import LogoutModal from "../../modals/LogoutModal";
import { IoIosArrowForward } from "react-icons/io";
import { sideScroll, userScroll } from "../../../utils/helpers";
import { useGetAdmin } from "../../../services/query/admin";

const SideDrawer = ({ isOpen, onClose }) => {
  const activeStyle = {
    backgroundColor: "#182444",
    alignItems: "center",
    display: "flex",
    color: "#fff",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    margin: "0 0 8px 0",
    padding: "13px 12px ",
    borderRadius: "10px",
  };
  const { data: admin } = useGetAdmin();
  const [show, setShow] = useState(false);

  return (
    <Drawer
      autoFocus={false}
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bgColor="red" color="#000">
        <DrawerBody p={0} sx={userScroll} overflowY="scroll">
          <Flex
            flexDir="column"
            justifyContent="space-between"
            position={"fixed"}
            zIndex="5"
            bg="#0F172B"
            w="full"
          >
            <Box h="100vh" overflowY="scroll" sx={sideScroll}>
              <Box
                bg="#0F172B"
                pos="sticky"
                top={0}
                pl="20px"
                pr="18px"
                pt="20px"
              >
                <Flex
                  pb="20px"
                  borderBottom="1px solid #333333"
                  align="center"
                  gap="30px"
                >
                  <Image src={logo} w="103px" h="29px" alt="logo" />
                  <Box
                    bg="#F3C948"
                    px="10px"
                    py="4px"
                    borderRadius="50px"
                    textAlign="center"
                  >
                    <Text color="#1E1E1E" fontSize="10px">
                      {admin?.data?.Role?.name}
                    </Text>
                  </Box>
                </Flex>
                <DrawerCloseButton color="#fff" />
              </Box>

              <Box px="20px" flex="1">
                <Box borderBottom="1px solid #333333" py="10px">
                  <Text mb="4px" color="#4D4D4D" fontSize="12px">
                    General
                  </Text>
                  {data?.general?.map((item, i) => (
                    <Box
                      key={i}
                      _hover={{
                        backgroundColor: "#182444",
                        borderRadius: "10px",
                      }}
                    >
                      <NavLink
                        to={item.path}
                        onClick={onClose}
                        style={({ isActive }) =>
                          isActive
                            ? { ...activeStyle }
                            : {
                                ...activeStyle,
                                backgroundColor: "",
                                color: "#828282",
                              }
                        }
                      >
                        <Image src={item.icon} w="16px" h="16px" mr="24px" />
                        {item.name}
                      </NavLink>
                    </Box>
                  ))}
                </Box>

                <Box borderBottom="1px solid #333333" py="10px">
                  <Text mb="4px" color="#4D4D4D" fontSize="12px">
                    Account
                  </Text>
                  {data?.acc?.map((item, i) => (
                    <Box
                      key={i}
                      _hover={{
                        backgroundColor: "#182444",
                        borderRadius: "10px",
                      }}
                    >
                      <NavLink
                        to={item.path}
                        onClick={onClose}
                        style={({ isActive }) =>
                          isActive
                            ? { ...activeStyle }
                            : {
                                ...activeStyle,
                                backgroundColor: "",
                                color: "#828282",
                              }
                        }
                      >
                        <Image src={item.icon} w="16px" h="16px" mr="24px" />
                        {item.name}
                      </NavLink>
                    </Box>
                  ))}
                </Box>

                <Box borderBottom="1px solid #333333" py="10px">
                  <Text mb="4px" color="#4D4D4D" fontSize="12px">
                    KYC
                  </Text>
                  {data?.kyc?.map((item, i) => (
                    <Box
                      key={i}
                      _hover={{
                        backgroundColor: "#182444",
                        borderRadius: "10px",
                      }}
                    >
                      <NavLink
                        to={item.path}
                        onClick={onClose}
                        style={({ isActive }) =>
                          isActive
                            ? { ...activeStyle }
                            : {
                                ...activeStyle,
                                backgroundColor: "",
                                color: "#828282",
                              }
                        }
                      >
                        <Image src={item.icon} w="16px" h="16px" mr="24px" />
                        {item.name}
                      </NavLink>
                    </Box>
                  ))}
                </Box>

                <Box py="13px">
                  <Text mb="4px" color="#4D4D4D" fontSize="12px">
                    Teams
                  </Text>
                  {data?.teams?.map((item, i) => (
                    <Box
                      key={i}
                      _hover={{
                        backgroundColor: "#182444",
                        borderRadius: "10px",
                      }}
                    >
                      <NavLink
                        to={item.path}
                        onClick={onClose}
                        style={({ isActive }) =>
                          isActive
                            ? { ...activeStyle }
                            : {
                                ...activeStyle,
                                backgroundColor: "",
                                color: "#828282",
                              }
                        }
                      >
                        <Image src={item.icon} w="16px" h="16px" mr="24px" />
                        {item.name}
                      </NavLink>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box px="20px" mb="30px" w="full">
                <Text mb="4px" color="#4D4D4D" fontSize="12px">
                  AdminProfile
                </Text>
                <Flex
                  mt="35px"
                  justifyContent="space-between"
                  align="center"
                  _hover={{ backgroundColor: "#182444", borderRadius: "10px" }}
                >
                  <NavLink
                    to="/settings"
                    onClick={onClose}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            ...activeStyle,
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }
                        : {
                            ...activeStyle,
                            backgroundColor: "",
                            color: "#828282",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }
                    }
                  >
                    <Flex gap="15px" align="center">
                      <Image
                        src={admin?.data?.avatar || pfp}
                        w="30px"
                        h="30px"
                      />
                      <Box>
                        <Text fontSize="12px">
                          {admin?.data?.firstName} {admin?.data?.lastName}
                        </Text>
                        <Text color="#F3C948" fontSize="10px">
                          {admin?.data?.Role?.name || ""}
                        </Text>
                      </Box>
                    </Flex>
                    <IoIosArrowForward color="#666666" />
                  </NavLink>
                </Flex>

                <Flex
                  mt="30px"
                  cursor="pointer"
                  gap="20px"
                  align="center"
                  onClick={() => setShow(true)}
                >
                  <Image src={log} />
                  <Text fontSize="12px" color="#808080">
                    Log Out
                  </Text>
                </Flex>
              </Box>
            </Box>
            <LogoutModal isOpen={show} onClose={() => setShow(false)} />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default React.memo(SideDrawer);
