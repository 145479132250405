import axiosInstance, { uploadInstance } from "../axiosInstance";
import * as API from "../url";

export const updateAdminProfile = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_ADMIN_PROFILE, body);
  return res.data;
};

export const updateWithdrawEmail = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_WITHDRAW_EMAIL, body);
  return res.data;
};

export const uploadAdminAvatar = async (body) => {
  const res = await uploadInstance.post(API.UPLOAD_ADMIN_AVATAR, body);
  return res.data;
};

export const changePassword = async (body) => {
  const res = await uploadInstance.post(API.CHANGE_PASSWORD, body);
  return res.data;
};

export const updateServiceCharge = async (body) => {
  const res = await uploadInstance.put(API.UPDATE_SERVICE_CHARGE, body);
  return res.data;
};

export const updateOneOffServiceCharge = async (body) => {
  const res = await uploadInstance.put(API.UPDATE_ONE_OFF_SERVICE_CHARGE, body);
  return res.data;
};

export const updateWithdrawalLimit = async (body) => {
  const res = await uploadInstance.put(API.UPDATE_WITHDRAWAL_LIMIT, body);
  return res.data;
};

export const updateOverdueLimit = async (body) => {
  const res = await axiosInstance.put(API.UPDATE_OVERDUE_LIMIT, body);
  return res.data;
};

export const updatePayStackCharge = async (body) => {
  const res = await uploadInstance.put(API.UPDATE_PAYSTACK_CHARGE, body);
  return res.data;
};

export const updateSchoolCharge = async (body) => {
  const res = await uploadInstance.put(API.UPDATE_SCHOOL_CHARGE, body);
  return res.data;
};

export const setIncentive = async (body) => {
  const res = await uploadInstance.put(API.setIncentive, body);
  return res.data;
};

export const sendPayOtp = async (body) => {
  const res = await uploadInstance.post(API.SEND_PAY_OTP, body);
  return res.data;
};

export const payProvider = async (body) => {
  const res = await uploadInstance.post(API.PAY_PROVIDER, body);
  return res.data;
};

export const sendMassMessage = async (body) => {
  const res = await axiosInstance.post(API.SEND_MASS_MESSAGE, body);
  return res.data;
};

export const sendSchMessage = async (body) => {
  const res = await axiosInstance.post(API.SEND_SCH_MESSAGE, body);
  return res.data;
};

export const sendParMessage = async (body) => {
  const res = await axiosInstance.post(API.SEND_PAR_MESSAGE, body);
  return res.data;
};

export const updateAdminRole = async (body) => {
  const res = await uploadInstance.put(API.UPDATE_ADMIN_ROLE, body);
  return res.data;
};

export const deleteAdmin = async (query) => {
  const res = await axiosInstance.delete(API.DEL_ADMIN(query));
  return res.data;
};

export const getAdmin = async () => {
  const res = await axiosInstance.get(API.GET_ADMIN);
  return res.data;
};

export const getOverdueLimit = async () => {
  const res = await axiosInstance.get(API.GET_OVERDUE_LIMIT);
  return res.data;
};

export const getStudentCount = async () => {
  const res = await axiosInstance.get(API.STUDENT_COUNT);
  return res.data;
};

export const getAppSettings = async () => {
  const res = await axiosInstance.get(API.GET_APP_SETTINGS);
  return res.data;
};
