import { lazy } from 'react';
import WithSuspense from '../components/Loaders/WithSuspense';
import { PUBLIC_PATHS, PRIVATE_PATHS } from './constants';
import { Navigate } from 'react-router-dom';

const { LOGIN, SIGNUP, RESET_PASS, CHANGE_PASS } = PUBLIC_PATHS;

const {
  OVERVIEW,
  SCHOOL,
  AGENT,
  SCHOOL_REC,
  SCHOOL_DETAILS,
  TRANSACTION,
  ROLES,
  COMMUNICATION,
  PROVIDER,
  EXTRAS,
  PARENT,
  SETTINGS,
  LOAN,
  KYC,
  PAYMENT_LOG,
} = PRIVATE_PATHS;

const Login = WithSuspense(lazy(() => import('../pages/Authentication/Login')));
const ChangePassword = WithSuspense(
  lazy(() => import('../pages/Authentication/ChangePassword'))
);
const ResetPassword = WithSuspense(
  lazy(() => import('../pages/Authentication/ResetPassword'))
);
const Signup = WithSuspense(
  lazy(() => import('../pages/Authentication/Signup'))
);
const Overview = WithSuspense(lazy(() => import('../pages/Overview/Overview')));
const School = WithSuspense(lazy(() => import('../pages/School/School')));
const Parent = WithSuspense(lazy(() => import('../pages/Parent/Parent')));
const Transactions = WithSuspense(
  lazy(() => import('../pages/Transactions/Transactions'))
);
const Agents = WithSuspense(lazy(() => import('../pages/Agents/Agents')));
const Roles = WithSuspense(lazy(() => import('../pages/Roles/Roles')));
const Communication = WithSuspense(
  lazy(() => import('../pages/Communication/Communication'))
);
const SchoolDetails = WithSuspense(
  lazy(() => import('../pages/School/SchoolDetails'))
);
const Extras = WithSuspense(
  lazy(() => import('../components/data/School/SchoolRecords/Extras'))
);
const SchoolRec = WithSuspense(
  lazy(() => import('../pages/School/SchoolRecord'))
);
const kyc = WithSuspense(lazy(() => import('../pages/Kyc/Kyc.js')));
const Settings = WithSuspense(lazy(() => import('../pages/Settings/Settings')));
const Loan = WithSuspense(lazy(() => import('../pages/Loan/Loan')));
const Provider = WithSuspense(lazy(() => import('../pages/Loan/Provider')));
const PaymentLog = WithSuspense(
  lazy(() => import('../pages/PaymentLog/PaymentLog.js'))
);

export const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: RESET_PASS, element: <ResetPassword /> },
  { path: CHANGE_PASS, element: <ChangePassword /> },
  { path: SIGNUP, element: <Signup /> },
  { path: '*', element: <Navigate to="/login" replace /> },
];

export const PRIVATE_ROUTES = [
  { path: OVERVIEW, element: <Overview /> },
  { path: SCHOOL, element: <School /> },
  { path: COMMUNICATION, element: <Communication /> },
  { path: SCHOOL_DETAILS, element: <SchoolDetails /> },
  { path: LOAN, element: <Loan /> },
  { path: SCHOOL_REC, element: <SchoolRec /> },
  { path: PARENT, element: <Parent /> },
  { path: TRANSACTION, element: <Transactions /> },
  { path: EXTRAS, element: <Extras /> },
  { path: AGENT, element: <Agents /> },
  { path: ROLES, element: <Roles /> },
  { path: PROVIDER, element: <Provider /> },
  { path: SETTINGS, element: <Settings /> },
  { path: KYC, element: <kyc /> },
  { path: PAYMENT_LOG, element: <PaymentLog /> },
  { path: '*', element: <Navigate to="/overview" replace /> },
];
