import React, { useState } from "react";
import { Box, Flex, Image, Skeleton, Spinner, Text } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { data } from "../../common/constants";
import { log, logo, pfp } from "../../../assets/exports";
import { IoIosArrowForward } from "react-icons/io";
import { sideScroll, useLogOut } from "../../../utils/helpers";
import { useGetAdmin } from "../../../services/query/admin";

const Sidebar = () => {
  const activeStyle = {
    backgroundColor: "#182444",
    alignItems: "center",
    display: "flex",
    color: "#fff",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    margin: "0 0 8px 0",
    padding: "13px 12px ",
    borderRadius: "10px",
  };

  const location = useLocation();

  const logOut = useLogOut();
  const { data: admin, isLoading } = useGetAdmin();

  const hasViewOverviewPermission = () => {
    return isLoading ? (
      <Skeleton isLoaded={!isLoading}></Skeleton>
    ) : (
      admin && "viewOverview" in admin?.data?.Role?.permissions
    );
  };
  const hasViewSchoolPermission = () => {
    return isLoading ? (
      <Skeleton isLoaded={!isLoading}></Skeleton>
    ) : (
      admin && "viewSchool" in admin?.data?.Role?.permissions
    );
  };
  const hasViewParentPermission = () => {
    return isLoading ? (
      <Skeleton isLoaded={!isLoading}></Skeleton>
    ) : (
      admin && "viewParentAndWards" in admin?.data?.Role?.permissions
    );
  };
  const hasTransactionPermission = () => {
    return isLoading ? (
      <Skeleton isLoaded={!isLoading}></Skeleton>
    ) : (
      admin && "viewTransactionPage" in admin?.data?.Role?.permissions
    );
  };

  const hasViewLoanPermission = () => {
    return isLoading ? (
      <Skeleton isLoaded={!isLoading}></Skeleton>
    ) : (
      admin && "viewLoanFacilityPage" in admin?.data?.Role?.permissions
    );
  };

  const hasViewNotificationPermission = () => {
    return isLoading ? (
      <Skeleton isLoaded={!isLoading}></Skeleton>
    ) : (
      admin && "viewNotifications" in admin?.data?.Role?.permissions
    );
  };

  const hasCommunicationPermission = () => {
    return isLoading ? (
      <Skeleton isLoaded={!isLoading}></Skeleton>
    ) : (
      admin && "updateSchool" in admin?.data?.Role?.permissions
    );
  };

  const hasRolesPermission = () => {
    return isLoading ? (
      <Skeleton isLoaded={!isLoading}></Skeleton>
    ) : (
      admin && "editRolesAndPermissions" in admin?.data?.Role?.permissions
    );
  };

  const MenuItem = ({ to, item, isActive }) => {
    const icon = isActive ? item.sec : item.icon;

    return (
      <Box _hover={{ backgroundColor: "#182444", borderRadius: "10px" }}>
        <NavLink
          to={to}
          style={({ isActive }) =>
            isActive
              ? { ...activeStyle }
              : {
                  ...activeStyle,
                  backgroundColor: "",
                  color: "#828282",
                }
          }
        >
          <Image src={icon} w="16px" h="16px" mr="24px" />
          {item?.name}
        </NavLink>
      </Box>
    );
  };
  const [loading, setLoading] = useState(false);

  const logs = () => {
    setLoading(true);
    setTimeout(() => {
      logOut();
    }, 1000);
  };

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      position={"fixed"}
      zIndex="5"
      bg="#0F172B"
      w="285px"
    >
      <Box h="100vh" overflowY="scroll" sx={sideScroll}>
        <Box bg="#0F172B" pos="sticky" top={0} pl="20px" pr="18px" pt="20px">
          <Flex
            pb="20px"
            borderBottom="1px solid #333333"
            align="center"
            gap="30px"
          >
            <Image src={logo} w="103px" h="29px" alt="logo" />
            <Box
              bg="#F3C948"
              px="10px"
              py="4px"
              borderRadius="50px"
              textAlign="center"
            >
              <Text color="#182444" fontSize="10px">
                {admin?.data?.Role?.name}
              </Text>
            </Box>
          </Flex>
        </Box>

        <Box px="20px" flex="1">
          {hasViewOverviewPermission() && (
            <Box borderBottom="1px solid #333333" py="10px">
              <Text mb="4px" color="#4D4D4D" fontSize="12px">
                General
              </Text>
              {data?.general?.map((item, i) => (
                <Skeleton isLoaded={!isLoading}>
                  <Box
                    key={i}
                    _hover={{
                      backgroundColor: "#182444",
                      borderRadius: "10px",
                    }}
                  >
                    <NavLink
                      to={item.path}
                      style={({ isActive }) =>
                        isActive
                          ? { ...activeStyle }
                          : {
                              ...activeStyle,
                              backgroundColor: "",
                              color: "#828282",
                            }
                      }
                    >
                      <Image
                        src={
                          location.pathname === item.path ? item.sec : item.icon
                        }
                        w="16px"
                        h="16px"
                        mr="24px"
                      />
                      {item.name}
                    </NavLink>
                  </Box>
                </Skeleton>
              ))}
            </Box>
          )}

          {!hasViewSchoolPermission() &&
          !hasViewParentPermission() &&
          !hasTransactionPermission() &&
          !hasViewLoanPermission() ? (
            ""
          ) : (
            <Box borderBottom="1px solid #333333" py="10px">
              <Text mb="4px" color="#4D4D4D" fontSize="12px">
                Account
              </Text>

              {data?.acc?.map((item, i) => {
                if (item?.name === "School" && !hasViewSchoolPermission()) {
                  return null;
                }
                if (item?.name === "Parent" && !hasViewParentPermission()) {
                  return null;
                }
                if (
                  item?.name === "Transactions" &&
                  !hasTransactionPermission()
                ) {
                  return null;
                }
                if (
                  item?.name === "Loan Facility" &&
                  !hasViewLoanPermission()
                ) {
                  return null;
                }

                return (
                  <Skeleton isLoaded={!isLoading}>
                    <MenuItem
                      key={i}
                      to={item.path}
                      item={item}
                      isActive={location.pathname === item.path}
                    />
                  </Skeleton>
                );
              })}
            </Box>
          )}

          {!hasCommunicationPermission() && !hasViewNotificationPermission() ? (
            ""
          ) : (
            <Box borderBottom="1px solid #333333" py="10px">
              <Text mb="4px" color="#4D4D4D" fontSize="12px">
                KYC
              </Text>

              {data?.kyc?.map((item, i) => {
                if (
                  item?.name === "Communication" &&
                  !hasCommunicationPermission()
                ) {
                  return null;
                }
                if (
                  item?.name === "Notifications" &&
                  !hasViewNotificationPermission()
                ) {
                  return null;
                }

                return (
                  <Skeleton isLoaded={!isLoading}>
                    <MenuItem
                      key={i}
                      to={item.path}
                      item={item}
                      isActive={location.pathname === item.path}
                    />
                  </Skeleton>
                );
              })}
            </Box>
          )}

          {!hasRolesPermission() ? (
            ""
          ) : (
            <Box py="13px">
              <Text mb="4px" color="#4D4D4D" fontSize="12px">
                Teams
              </Text>

              {data?.teams?.map((item, i) => {
                return (
                  <Skeleton isLoaded={!isLoading}>
                    <MenuItem
                      key={i}
                      to={item.path}
                      item={item}
                      isActive={location.pathname === item.path}
                    />
                  </Skeleton>
                );
              })}
            </Box>
          )}
        </Box>
        <Box px="20px" mb="30px" py="10px">
          <Text mb="4px" color="#4D4D4D" fontSize="12px">
            Settings
          </Text>
          <Flex
            mt="35px"
            justifyContent="space-between"
            align="center"
            _hover={{ backgroundColor: "#182444", borderRadius: "10px" }}
          >
            <NavLink
              to="/settings"
              style={({ isActive }) =>
                isActive
                  ? { ...activeStyle }
                  : {
                      ...activeStyle,
                      backgroundColor: "",
                      color: "#828282",
                    }
              }
            >
              <Flex gap="15px" align="flex-start">
                <Image
                  rounded="full"
                  src={admin?.data?.avatar || pfp}
                  w="30px"
                  h="30px"
                />
                <Box>
                  <Text fontSize="12px">
                    {admin?.data?.firstName} {admin?.data?.lastName}
                  </Text>
                  <Text color="#F3C948" fontSize="10px">
                    {admin?.data?.Role?.name || ""}
                  </Text>
                </Box>
              </Flex>
              <Box marginLeft="14">
                <IoIosArrowForward color="#666666" />
              </Box>
            </NavLink>
          </Flex>

          <Flex
            mt="30px"
            cursor="pointer"
            onClick={logs}
            gap="20px"
            align="center"
          >
            {loading ? <Spinner color="red" size="xs" /> : <Image src={log} />}
            <Text fontSize="12px" color={loading ? "red" : "#808080"}>
              {loading ? "Logging Out" : "Log Out"}
            </Text>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default React.memo(Sidebar);
