import { formatDates } from '../utils/helpers';

export const LOGIN = process.env.REACT_APP_BASE_URL + 'admin-login';
export const TOTAL_PARENT = 'admin/total-enrolled-parents';
export const TOTAL_SCHOOL = 'admin/total-schools';
export const TOTAL_WARDS = 'admin/total-wards';
export const VERIFY_PASSWORD = 'admin/verify-reset-password';
export const SEND_PASSWORD_OTP = 'admin/reset-password-request';
export const TOTAL_TRANSACTION_VOLUME = 'admin/total-transaction-volume';
export const DEL_DISCOUNT = (id = '') => `admin/delete-discount/${id}`;
export const GET_OVERDUE = (id = '') => `admin/get-parent-overdue/${id}`;
export const DELETE_WARD = (id = '') => `admin/delete-ward/${id}`;
export const DURATION_LEFT = (id = '') =>
  `admin/get-parent-plan-count?parentId=${id}`;
export const GIVE_DICOUNT = 'admin/create-discount';
export const FUND_WALLET = 'admin/fund-school-wallet';
export const CHANGE_PLAN_PAYMENT = 'admin/change-parent-payment-type';
export const UPDATE_PARENT = 'admin/update-parent';
export const UPDATE_PLAN = 'admin/update-plan-values';
export const UPDATE_PLAN_TRANSACTION = 'admin/update-plan-transaction-values';
export const UPDATE_TRANSACTION = 'admin/update-transaction-history-values';
export const GET_SCHOOL_PARENT = 'admin/get-school-parents';
export const CREATE_DIVISION = 'admin/create-division';
export const UPDATE_DIVISION = 'admin/update-division';
export const DELETE_DIVISION = 'admin/delete-division';
export const DELETE_EXTRA = 'admin/delete-extra';
export const GET_PLANS_COUNT = 'admin/plans-info';
export const GET_PLANS_PERFOMANCE = 'admin/plans-performance';
export const DELETE_SCHOOl = 'admin/delete-school-owner';
export const SUSPEND_SCHOOl = 'admin/change-user-status';
export const DELETE_PARENT = 'admin/delete-parent';
export const GET_ADMIN = 'admin-info';
export const INITIATE_OVERDUE = 'admin/intiate-overdue-payment';
export const GET_CASH_INFLOW = 'admin/total-cash-inflow';
export const GET_CASH_OUTFLOW = 'admin/total-cash-outflow';
export const APPROVED_LOANS = 'total-approved-loans';
export const APPROVE_LOAN = 'approve-loan';
export const DECLINE_LOAN = 'decline-loan';
export const REQUESTED_LOANS = 'total-loan-requests';
export const LOAN_HISTORY = 'admin/get-loan-requests';
export const PAID_LOANS = 'total-repaid-loans';
export const LOAN_PROV_HISTORY = 'loan-provider-loan-history';
export const UPDATE_PAR_STATUS = 'admin/update-parent';
export const BANK_CODE = 'banks/getBanks';
export const LOAN_RATE = 'admin/update-loan-eligibility-rate';
export const VERIFY_ACC = 'banks/resolveBankName';
export const SEND_MASS_MESSAGE = 'admin/send-mass-message';
export const SEND_SCH_MESSAGE = 'admin/send-message-to-school';
export const SEND_PAR_MESSAGE = 'admin/send-message-to-parent';
export const UPDATE_EXTRA = 'admin/update-extra';
export const UPDATE_MIN_LOAN = 'update-min-loan-amount';
export const CREATE_LOAN_PROVIDER = 'create-loan-provider';
export const UPDATE_LOAN_PROVIDER = 'update-loan-provider';
export const GET_APP_SETTINGS = 'admin/get-app-setting';
export const DELETE_LOAN_PROVIDER = (id = '') =>
  `delete-loan-provider?loanProviderId=${id}`;
export const GET_PARENT_DETAILS = (parentId = '', schoolId = '') =>
  `admin/get-parent-data?parentId=${parentId}&schoolId=${schoolId}`;
export const GET_PARENT_DETAIL = 'admin/get-parent-data';
export const ELIGIBLE_SCHOOL = 'get-eligible-schools';
export const GET_LOAN_PROVIDER = 'get-loan-providers';
export const LOAN_PROVIDER_LOGO = 'upload-loan-provider-logo';
export const GET_LOAN_DETAIL = (id = '') => `get-loan-detail/${id}`;
export const CHANGE_PASSWORD = 'admin/update-password';
export const GET_PLAN_FLOW = 'admin/cash-inflow-by-plan';
export const GET_OVERDUE_PARENT = 'admin/get-all-overdues';
export const FUND_PARENT = 'admin/fund-parent-wallet';
export const DEBIT_PARENT = 'admin/debit-parent-wallet';
export const GET_SCHOOLS_TRANSACTION = 'admin/schools-transactions';
export const GET_PARENT_TRANSACTION = 'admin/get-all-parents-transactions';
export const GET_SCHOOL_TRANSACTION = 'admin/get-school-transaction';
export const UPDATE_SCHOOL_SERVICE_CHARGE = 'admin/change-school-service-fee';
export const UPDATE_SERVICE_CHARGE = 'admin/update-service-charge';
export const UPDATE_ONE_OFF_SERVICE_CHARGE =
  'admin/set-service-charge-cap-for-oneoffs';
export const UPDATE_WITHDRAWAL_LIMIT = 'admin/update-school-withdrawal-limit';
export const UPDATE_OVERDUE_LIMIT = 'admin/update-overdue-limit';
export const GET_OVERDUE_LIMIT = 'admin/get-overdue-limit';
export const UPDATE_PAYSTACK_CHARGE = 'admin/update-paystack-charge';
export const UPDATE_SCHOOL_CHARGE = 'admin/change-school-service-fee';
export const GET_TOP_PARENT = 'admin/top-schools-with-most-students';
export const GET_LAST_SIX_MONTH = 'admin/cash-flow-last-six-months';
export const GET_PARENTS = 'admin/parents-data';
export const GET_SCHOOLS = 'admin/schools-data';
export const GET_WITHDRAWAL_REQUEST = 'admin/get-withdrawal-requests';
export const APPROVE_WITHDRAWAL = 'admin/approve-withdrawal-requests';
export const STUDENT_COUNT = 'admin/total-students-count';
export const CHANGE_FIRST_PASSWORD = 'admin/change-first-password';
export const GET_SCHOOL_DETAIL = (id = '') => `admin/get-school?schoolId=${id}`;
export const DEL_ADMIN = (id = '') => `admin/delete-admin/${id}`;
export const GET_SCHOOL = 'admin/get-school';
export const PAY_PROVIDER = 'pay-loan-provider';
export const SEND_PAY_OTP = 'send-loan-provider-otp';
export const INV_PARENT = 'admin/invite-parent';
export const TOTAL_STUDENTS = (id = '') =>
  `admin/get-school-students?schoolId=${id}`;
export const TOTAL_STUDENT = 'admin/get-school-students';
export const CREATE_STUDENT = 'admin/create-student';
export const UPDATE_STUDENT = 'admin/update-ward';
export const CREATE_EXTRA = 'admin/create-extra';
export const GET_ROLES = 'admin/get-roles';
export const INV_ADMIN = 'invite-admin';
export const UPDATE_ADMIN_ROLE = 'admin/update-admin-role';
export const CREATE_ROLES = 'admin/create-role';
export const EDIT_ROLES = 'admin/edit-role';
export const GET_SCHOOL_FLOW = 'admin/school-transaction-flows';
export const GET_PERMISSIONS = 'admin/get-permissions';
export const GET_ADMINS = 'get-admins';
export const GET_EXTRA_COUNT = 'admin/students-count-per-extra';
export const UPDATE_ADMIN_PROFILE = 'admin/update-profile';
export const UPDATE_WITHDRAW_EMAIL = 'update-withdrawal-email';
export const UPLOAD_ADMIN_AVATAR = 'admin/upload-avatar';

export const DEL__DEF_EXTRA = (id = '') => `admin/delete-default-extra/${id}`;
export const GET_PAID_DURATION = (id = '') =>
  `admin/get-duration-count/?parentId=${id}`;
export const GET_DEFAULT_EXTRAS = (id = '') =>
  `admin/get-school-default-extras?schoolId=${id}`;
export const GET_PAR_PLAN_TRAN = (id = '') =>
  `admin/get-parent-plan-transactions?parentId=${id}&page=1`;
export const CREATE_DEF_EXTRA = 'admin/create-default-extra';
export const UPDATE_DEF_EXTRA = 'admin/update-default-extra';
export const SWITCH_PLAN = 'admin/switch-parent-plan';
const currentYear = new Date().getFullYear();
const yearStart = new Date(currentYear, 0, 1);
const yearEnd = new Date(currentYear, 11, 31);

export const PAR_TRAN = (id = '') =>
  `admin/get-parent-transactions?parentId=${id}&startDate=${formatDates(
    yearStart
  )}&endDate=${formatDates(yearEnd)}&page=1`;
export const PAR_PLAN = (id = '') => `admin/get-parent-plans/${id}?page=1`;

export const setIncentive = 'admin/set-incentives';
export const PAR_EXTRA = (id = '', enrollmentId = '') =>
  `admin/get-parent-ward-extra-history/${id}?page=1&enrollmentId=${enrollmentId}`;

export const DAILY = (parentId = '', schoolId = '') =>
  `parent/${parentId}/${schoolId}/get-daily-plan-details`;
export const WEEKLY = (parentId = '', schoolId = '') =>
  `parent/${parentId}/${schoolId}/get-weekly-plan-details`;
export const MONTHLY = (parentId = '', schoolId = '') =>
  `parent/${parentId}/${schoolId}/get-monthly-plan-details`;
export const ONEOFF = (parentId = '', schoolId = '') =>
  `parent/${parentId}/${schoolId}/get-one-off-plan-details`;
export const FULLYEAR = (parentId = '', schoolId = '') =>
  `parent/${parentId}/${schoolId}/get-full-year-plan-details`;
export const GET_SINGLE_SCHOOL_PARENTS = 'admin/get-single-school-parents';
export const GET_TOTAL_OVERDUE = 'admin/get-school-total-overdue';
export const GET_TOTAL_FEE = 'admin/school-total-expected-fee';
export const UPDATE_DIVISION_PLAN = 'admin/update-division-and-plan';
export const GET_DIVISION = 'admin/school/divisions';
export const GET_PLAN_OUTSTANDING = (parentId = '', schoolId = '') =>
  `admin/get-plan-outstanding/${parentId}/${schoolId}`;
export const GET_TOTAL_AMOUNT = (parentId = '', schoolId = '') =>
  `admin/get-plan-total-amount/${parentId}/${schoolId}`;
export const GET_TOTAL_PAID = (parentId = '', schoolId = '') =>
  `admin/get-plan-total-paid/${parentId}/${schoolId}`;

export const GET_EXPECTED_PAYMENT = (schoolId = '', parentId = '') =>
  `admin/get-parent-expected-payment/${schoolId}/${parentId}`;
