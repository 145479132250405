import React, { useEffect } from "react";
import { Box, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import SideBar from "./AuthLayout/SideBar";
import Header from "./AuthLayout/Header";
import SideBarr from "./NonAuthLayout/SideBarr";
import { useLocation } from "react-router-dom";
import { logos } from "../../assets/exports";
import AuthBase from "../common/AuthBase";
import { useGetAdmin } from "../../services/query/admin";

export const AuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 991px)");
  const { data: userData } = useGetAdmin();

  useEffect(() => {
    if (userData) {
      sessionStorage.setItem("email", userData?.data?.email);
    }
  }, [userData]);

  useEffect(() => {
    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ];
    const resetIdleTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        localStorage.clear();
        window.location.href = "/login";
      }, 420000);
    };
    events.forEach((event) => window.addEventListener(event, resetIdleTimer));

    let timeoutId = setTimeout(() => {
      localStorage.clear();
      window.location.href = '/login';
    }, 420000);

    return () => {
      clearTimeout(timeoutId);
      events.forEach((event) =>
        window.removeEventListener(event, resetIdleTimer)
      );
    };
  }, []);

  return (
    <Box
      overflowX={isMobile ? "hidden" : ""}
      color="#000"
      fontFamily="Poppins"
      bg="#fff"
      position="relative"
    >
      {isMobile && <Header />}
      <Box minH="100vh" overflowX="hidden">
        {!isMobile && <SideBar />}
        <Box
          w="100%"
          pt={isMobile ? "60px" : "20px"}
          minH="100vh"
          color="#828282"
          pl={!isMobile ? "305px" : "20px"}
          pr={!isMobile ? "24px" : "20px"}
          pb={10}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const NonAuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 991px)");
  const location = useLocation();

  return (
    <Box color="#000" fontFamily="Poppins" bg="#fff" position="relative">
      <Flex minH="100vh">
        <Flex pb="30px" flexDir="column" w={!isMobile ? "50%" : "100%"}>
          <Box bg="#fff" pos="sticky" top="0" py="20px" px="38px">
            <Flex gap="13px" align="center">
              <Image src={logos} w="140px" h="50px" />
            </Flex>
          </Box>

          <Box
            flex="1"
            mb={location.pathname === "/login" && "50px"}
            pt={!isMobile ? "40px" : 10}
            px={!isMobile ? "80px" : 10}
          >
            {children}
          </Box>

          <AuthBase />
        </Flex>

        {!isMobile && <SideBarr />}
      </Flex>
    </Box>
  );
};
